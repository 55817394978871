import DatePicker from 'vue2-datepicker';
import commonHelper from '@/app/utility/common.helper.utility';
import store from '../../../../../store';
import appStrings from '@/app/utility/string.utility';
export default {
  name: 'addWareHouseMgmt',
  components: {
    DatePicker
  },
  props: ['rgpDetails'],
  data() {
    return {
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      unsubscribe: null,
      loader: false,
      isSuccess: false,
      showAlert: false,
      responseMsg: '',
      editMode: false,
      rgpNumber: null,
      updatedBy: this.getUserName(),
      itemCode: null,

      costCenter: {
        text: null,
        value: null
      },
      remarks: null,
      showItemModal: false,
      status: null,
      updatedDate: commonHelper.formattedDate(new Date()),
      itemFields: [
        {
          key: 'add',
          stickyColumn: true
        },
        {
          key: 'remove',
          stickyColumn: true
        },
        {
          key: 'item_code'
        },
        {
          key: 'item_desc',
          label: 'Description'
        },
        {
          key: 'wareHouse'
        },
        {
          key: 'wh_awb',
          label: 'WH AWB'
        },
        {
          key: 'wh_qty_sent'
        },
        {
          key: 'cost_center'
        },
        {
          key: 'cc_qty_sent',
          label: 'CC QTY SENT'
        },
        {
          key: 'cc_awb',
          label: 'CC AWB'
        },
        {
          key: 'wh_line_comment',
          label: 'Comment'
        }
      ],
      itemData: [
        {
          rgp_hdr_id: 0,
          inv_item_id: null,
          rgp_dtl_id: 0,
          warehouse: null,
          warehouse_id: 0,
          cost_center_id: 0,
          cc_qty_sent: null,
          wh_qty_sent_isEnable: false,
          wh_qty_rec: null,
          wh_qty_rec_isEnable: false,
          item_desc: null,
          wh_qty_sent: null,
          line_status: null,
          item_code: null,
          wh_update_date: null,
          wh_awb: null,
          wh_awb_isEnable: false,
          wh_line_remarks: null,
          wh_line_comment: null,
          wh_balance: null,
          wh_line_comment_isEnable: false,
          cost_center: null,
          wareHouse: null
        }
      ],
      index: null,
      showValueSetModal: false,
      quantityFlag: true,
      tempFlag: true,
      rgpHdrId: 0
    };
  },
  mounted() {
    if (this.rgpDetails) {
      this.rgpHdrId = this.rgpDetails.rgp_hdr_id;
      this.getWareHouseDataByHdrId(this.rgpDetails.rgp_hdr_id);
    } else {
      this.editMode = true;
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.addEditWarehouseGridData();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    getWareHouseDataByHdrId(rgpHdrId) {
      const payload = {
        rgpHdrId: rgpHdrId
      };
      this.loader = true;
      this.$store
        .dispatch('rgpManagement/getWareHouseDataByHdrId', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data;
            this.rgpNumber = result.rgp_num;
            this.updatedBy = result.last_update_name;
            this.updatedDate = result.update_date;
            this.remarks = result.wh_remarks;
            this.status = result.status;
            this.itemData = result.wh_details;
            // .map(item => {
            //   item.wh_qty_rec_isEnable = true;
            //   item.wh_qty_sent_isEnable = true;
            //   item.wh_awb_isEnable = true;
            //   item.tempFlag = true;
            //   item.wh_line_comment_isEnable = true;
            //   return item;
            // });
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addEditWarehouseGridData() {
      const warehouseDetails = this.itemData.map(elem => {
        return {
          inv_item_id: elem.inv_item_id,
          rgp_dtl_id: elem.rgp_dtl_id,
          wh_awb: elem.wh_awb,
          wh_qty_sent: elem.wh_qty_sent,
          wh_line_comment: elem.wh_line_comment,
          warehouse_id: elem.warehouse_id,
          cost_center_id: elem.cost_center_id
        };
      });

      const payload = {
        wh_remarks: this.remarks,
        rgp_hdr_id: this.rgpHdrId,
        wh_details: warehouseDetails ? warehouseDetails : null
      };
      this.loader = true;
      this.$store
        .dispatch('rgpManagement/addEditWarehouseGridData', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.getWareHouseDataByHdrId(this.rgpDetails.rgp_hdr_id);
            this.showButtonFlag = true;
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    getUserName() {
      const tempName = store.state.auth.userName;
      const name = tempName.slice(tempName.lastIndexOf('-') + 1);
      return name;
    },
    openCloseModal(flag, itemIndex) {
      this.showItemModal = flag;
      this.index = itemIndex;
    },
    validateWhQtyRec(rowDetails) {
      const getTotalQtySent = this.itemData.reduce(
        (curr = rowDetails.cc_qty_rec, prev) =>
          prev.item_code === rowDetails.item_code
            ? curr + prev.cc_qty_sent
            : curr,
        0
      );
      const getTotalQtyRec = this.itemData.reduce(
        (curr, prev) =>
          prev.item_code === rowDetails.item_code
            ? curr + prev.wh_qty_rec
            : curr,
        0
      );
      if (getTotalQtySent < getTotalQtyRec) {
        rowDetails.wh_qty_rec = null;
        return alert(
          'The Sum of WH Quantity Received value should not be greater than CC Quantity Sent value'
        );
      }
    },
    validateWhQtySent(rowDetails) {
      const getTotalWhQtySent = this.itemData.reduce(
        (curr = rowDetails.wh_qty_sent, prev) =>
          prev.item_code === rowDetails.item_code
            ? curr + prev.wh_qty_sent
            : curr,
        0
      );
      const getTotalQtyRec = this.itemData.reduce(
        (curr, prev) =>
          prev.item_code === rowDetails.item_code
            ? curr + prev.wh_qty_rec
            : curr,
        0
      );
      if (getTotalWhQtySent > getTotalQtyRec) {
        rowDetails.wh_qty_sent = null;
        return alert(
          'The Sum of WH Quantity Sent value value should not be greater than WH Quantity Received value'
        );
      }
    },
    addNewRow() {
      if (this.editMode) {
        this.itemData.push({
          balance: null,
          cc_AWB: null,
          cc_qty_rec: null,
          cc_qty_sent: null,
          inv_item_id: 0,
          item_code: null,
          costCenter: null,
          wareHouse: null,
          item_desc: null,
          last_update_date: null,
          line_status: null,
          rgp_dtl_id: 0,
          cost_center_id: 0,
          warehouse_id: 0,
          rgp_hdr_id: 0,
          wh_qty_sent: null,
          tempFlag: true,
          addFlag: true
        });
      }
    },
    removeRow(index) {
      if (this.editMode) {
        this.itemData.splice(index, 1);
      }
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.selectedIndex = index;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.RGP_INV_ITEMS) {
        this.itemData[this.selectedIndex].item_code = item.value_code;
        this.itemData[this.selectedIndex].inv_item_id = item.value_set_dtl_id;
        this.itemData[this.selectedIndex].item_desc = item.item_desc;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.WAREHOUSE_LIST) {
        this.itemData[this.selectedIndex].warehouse = item.value_code;
        this.itemData[this.selectedIndex].warehouse_id = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.COST_CENTER_LIST) {
        this.itemData[this.selectedIndex].cost_center = item.value_code;
        this.itemData[this.selectedIndex].cost_center_id =
          item.value_set_dtl_id;
        this.selectedIndex = null;
      }
      this.itemData = JSON.parse(JSON.stringify(this.itemData));
    },
    clearVsetValues(vsetCode, index) {
      if (vsetCode === 'RGP_INV_ITEMS') {
        this.itemData[index].item_code = null;
        this.itemData[index].inv_item_id = null;
        this.itemData[index].item_desc = null;
      } else if (vsetCode === 'WAREHOUSE_LIST') {
        this.itemData[index].warehouse = null;
        this.itemData[index].warehouse_id = null;
      } else if (vsetCode === 'COST_CENTER_LIST') {
        this.itemData[index].cost_center = null;
        this.itemData[index].cost_center_id = null;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
