import DatePicker from 'vue2-datepicker';
import commonHelper from '@/app/utility/common.helper.utility';
import addWareHouseMgmt from './addWareHouseMgmt';
import User from '../../admin/user';
import appStrings from '@/app/utility/string.utility';
// import itemCode from './itemCode';
export default {
  name: 'wareHouseMgmt',
  components: {
    DatePicker,
    addWareHouseMgmt,
    User,
    // itemCode
  },
  watch: {
    currentPage: function() {
      this.getWareHouseGridData();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getWareHouseGridData();
    }
  },
  data() {
    return {
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      unsubscribe: null,
      loader: false,
      isSuccess: false,
      showAlert: false,
      responseMsg: '',
      editMode: false,
      rgpNumber: null,
      updatedBy: {
        text: null,
        value: null
      },
      itemCode: {
        text: null,
        value: null
      },
      costCenter: {
        text: null,
        value: null
      },
      wareHouse: {
        text: null,
        value: null
      },
      showValueSetModal: false,
      receivedDate: [],
      showAddWareHouseMgmtModal: false,
      wareHouseMgmtFields: [
        {
          key: 'rgp_hdr_id'
        },
        {
          key: 'rgp_num',
          label: 'RGP Number'
        },
        {
          key: 'wh_update_date',
          label: 'Update Date  '
        },
        {
          key: 'last_update_name',
          label: 'Updated By'
        },
        {
          key: 'warehouse'
        },
        {
          key: 'item_code'
        },
        {
          key: 'item_desc',
          label: 'Item Description'
        },
        {
          key: 'cc_qty_sent',
          label: 'CC Quantity Sent'
        },
        {
          key: 'wh_qty_sent',
          label: 'WH Quantity Sent'
        },
        {
          key: 'cost_center'
        },
        {
          key: 'wh_awb',
          label: 'AWB WH'
        },
        {
          key: 'wh_line_comment',
          label: 'Comment'
        }
      ],
      wareHouseMgmtData: [],
      showUserModal: false,
      invCode: null,
      desc: null,
      rgpDetails: null
    };
  },
  mounted() {
  },
  methods: {
    getWareHouseGridData() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        rgp_num: this.rgpNumber,
        wh_updated_by: this.updatedBy.value,
        inv_item_id: this.itemCode.value,
        warehouse_id: this.wareHouse.value,
        cost_center_id: this.costCenter.value,
        wh_update_date_from: commonHelper.formattedDate(this.receivedDate[0]),
        wh_update_date_to: commonHelper.formattedDate(this.receivedDate[1])
      };
      this.loader = true;
      this.$store
        .dispatch('rgpManagement/getWareHouseGridData', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.wareHouseMgmtData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    openValueSetModal(vsetCode) {
      // To Open Value Set Modal
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      // To Close Value Set Modal
      this.showValueSetModal = false;
    },
    selectedvalueSet(item) {
      // To Selected Value from value set modal
      if (this.vsetCode === appStrings.VALUESETTYPE.COST_CENTER_LIST) {
        this.costCenter = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.WAREHOUSE_LIST) {
        this.wareHouse = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.RGP_INV_ITEMS) {
        this.itemCode = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
      }
    },
    rowSelected(item) {
      this.rgpDetails = item;
      this.showHideWareHouseMgmtModal(true);
    },
    showHideWareHouseMgmtModal(flag) {
      this.showAddWareHouseMgmtModal = flag;
    },
    showHideUserModal(flag) {
      this.showUserModal = flag;
    },
    selectedUser(item) {
      this.updatedBy.text = item.name;
      this.showUserModal = false;
    },
    openCloseModal(flag) {
      this.showItemModal = flag;
    },
    clearSearchParams() {
      this.rgpNumber = null;
      this.updatedBy = {
        value: null,
        text: null
      };
      this.itemCode = {
        value: null,
        text: null
      };
      this.costCenter = {
        text: null,
        value: null
      };
      this.wareHouse = {
        text: null,
        value: null
      };
      this.receivedDate = [];
      this.wareHouseMgmtData = [];
      this.totalRows = null;
      this.currentPage = 1;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === 'RGP_INV_ITEMS') {
        this.itemCode = {
          text: null,
          value: null
        };
      } else if (vsetCode === 'COST_CENTER_LIST') {
        this.costCenter = {
          text: null,
          value: null
        };
      } else if (vsetCode === 'WAREHOUSE_LIST') {
        this.wareHouse = {
          text: null,
          value: null
        };
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
